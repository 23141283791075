import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { getSensorModels } from "../Worker/filemanager.js";
import { Divider, Typography, FormControl, MenuItem, Box, List, CircularProgress, InputAdornment, ListItem, ListItemButton, ListItemText, Chip, InputLabel, createFilterOptions } from '@mui/material';
import { CustomSelect, Main, CustomField, CustomAutocomplete } from "../common/StyledComponents"
import CustomDrawer from "../common/CustomDrawer/index.jsx";
import { sortArrayOfObjects } from "../../utils.js"
import Uplink from "./Uplink.jsx";
import Downlink from "./Downlink.jsx";
import Statistics from "./Statistics.jsx";
import debounce from 'lodash.debounce';
import AuthContext, { USER_SCOPES } from "../../context/AuthContext";
import NotificationContext from "../../context/NotificationContext";
import { DEVICES_PER_PAGE, MAX_DEVICES } from "../../constants/types.constant.js";
import { getCustomerApplications, getSubCustomerApplications, getSubCustomers, getCustomerDevices, getSubCustomerDevices, getApplicationDevices, getDeviceById } from "../Worker/ns.js";

const filter = createFilterOptions();

const getSensors = () => {
    let sensors = getSensorModels() || [];
    sensors = sortArrayOfObjects(sensors, "sensor");
    sensors = [{ sensor: "Custom sensor" }, ...sensors]
    return sensors;
}

export default function NSDecoder() {
    const { userInfo } = useContext(AuthContext);
    const notificationCtx = useContext(NotificationContext)
    const sensors = getSensors();
    const [selectedSensor, setSelectedSensor] = useState(sensors[0]);
    const [applications, setApplication] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState("");
    const [devices, setDevices] = useState(null);
    const [isLargeNumbers, setIsLargeNumbers] = useState(false);
    const [isCustomer, setIsCustomer] = useState(true);
    const [subCustomers, setSubCustomers] = useState(null);
    const [selectedSubCustomerId, setSelectedSubCustomerId] = useState("");
    const [selectedDevice, setSelectedDevice] = useState({ id: "" });
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(null);
    const [linkType, setLinkType] = useState("Uplink");
    const [isOpen, setIsOpen] = useState(true);

    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);

    const handleSensorChange = (sensorName) => {
        let sensor = sensors.find(sensor => sensor.sensor === sensorName);
        setSelectedSensor(sensor);
    }

    async function updateApplications() {
        let resp;
        if (userInfo.scope == USER_SCOPES.CUSTOMER) {
            if (selectedSubCustomerId && selectedSubCustomerId != "*") {
                resp = await getSubCustomerApplications(selectedSubCustomerId)
            } else {
            resp = await getCustomerApplications()
            }
        } else if (userInfo.scope == USER_SCOPES.SUBCUSTOMER) {
            resp = await getSubCustomerApplications(userInfo.subCustomerId)
        }
        if (resp !== null) {
            let apps = [];
            for (var i = 0; i < resp.length; i++) {
                let item = {}
                item.name = resp[i].name
                item.id = resp[i].id.id
                apps.push(item)
            }
            setApplication(apps);
            setSelectedApplication("");
        }
    }

    async function updateKeys() {
        console.log("Updating keys")
        if (selectedDevice.id !== "") {
            const resp = await getDeviceById(selectedDevice.id);
            if (resp !== null) {
                let temp = selectedDevice;
                temp.nwkSKey = resp.nwkSKey
                temp.appSKey = resp.appSKey
                console.log("New device: ", temp)
                setSelectedDevice(temp)
            }
        }
    }

    function requestsArray(applicationId, count) {
        let array = [];
        for (let i = 1; i < count; i++) {
            let searhParam = { page: i }
            let request = getDevices(applicationId, searhParam)
            array.push(request);
        }
        return array;
    }

    const converToDevices = (arr) => {
        let newDevices = [];
        for (var i = 0; i < arr.length; i++) {
            let item = {}
            item.name = arr[i].name
            item.eui = arr[i].deviceEUI
            item.id = arr[i].id.id
            item.appKey = arr[i].appKey;
            item.nwkSKey = arr[i].nwkSKey
            item.appSKey = arr[i].appSKey
            item.modelName = arr[i].deviceModelName;
            item.createdTime = arr[i].createdTime;
            newDevices.push(item);
        }
        return newDevices;
    }

    function updateDevices(id) {
        setDevices(null);
        setSelectedDevice({ id: "" });
        setIsLargeNumbers(false);
        getDevices(id).then((resp) => {
            let devices = converToDevices(resp.data);
            setDevices(devices)
            let totalDevices = resp.totalElements;
            if (totalDevices > DEVICES_PER_PAGE) {
                let pages = totalDevices < MAX_DEVICES ? Math.ceil(totalDevices / DEVICES_PER_PAGE) : MAX_DEVICES / DEVICES_PER_PAGE;
                Promise.all(requestsArray(id, pages)).then(allResults => {
                    const total = allResults.reduce((arr, row) => {
                        return arr.concat(row.data);
                    }, []);
                    let newDevices = converToDevices(total);
                    if (totalDevices > MAX_DEVICES) {
                        setIsLargeNumbers(true);
                        newDevices.push({ id: "tooManyDevs", name: "Too many devices. Please use search" })
                    }
                    setDevices(oldDevices => [...oldDevices, ...newDevices]);
                })
            }
        }).catch(() => {
            notificationCtx.error("Could not get devices. Please try again!")
        })
    }

    async function updateSubCustomers() {
        const resp = await getSubCustomers();
        if (resp !== null) {
            setSubCustomers(resp);
        }
    }

    useEffect(() => {
        if (userInfo) {
            if (userInfo.scope == USER_SCOPES.CUSTOMER) {
                setIsCustomer(true);
                updateSubCustomers();
            } else {
                setIsCustomer(false)
            }
            updateApplications();
            localStorage.setItem("id", 0)
        }
    }, [userInfo])

    useEffect(() => {
        if (selectedSubCustomerId !== "") {
            updateApplications();
        }
    }, [selectedSubCustomerId])

    useEffect(() => {
        if (selectedApplication !== "") {
            updateDevices(selectedApplication);
        }
    }, [selectedApplication])

    const showContent = () => {
        switch (linkType) {
            case "Uplink":
                return <Uplink sensorDecoder={selectedSensor?.decoder}
                               device={selectedDevice}
                               updateKeys={updateKeys} />
            case "Downlink":
                return <Downlink sensorEncoder={selectedSensor?.encoder}
                                 sensorModelName={selectedSensor?.model}
                                 deviceId={selectedDevice.id}
            />
            case "Statistics":
                return <Statistics deviceId={selectedDevice.id} />
        }
    }


    function getDevices(applicationId, searchParams) {
        if (applicationId) {
            if (applicationId == "*") {
                if (userInfo.scope == USER_SCOPES.CUSTOMER) {
                    return getCustomerDevices(userInfo.customerId, searchParams)
                } else if (userInfo.scope == USER_SCOPES.SUBCUSTOMER) {
                    return getSubCustomerDevices(userInfo.subCustomerId, searchParams)
                }
            } else {
                return getApplicationDevices(applicationId, searchParams);
            }
        }
    }

    async function getFilteredDevices(applicationId, filterStr) {
        let newOptions = [];
        let searchParams = { filter: filterStr }
        const resp = await getDevices(applicationId, searchParams);
        if (resp !== null) {
            let data = resp.data;
            newOptions = converToDevices(data);
        }
        setFilteredOptions(newOptions);
    }

    useEffect(() => {
        if (isLargeNumbers && inputValue !== "") {
            debouncedFilter(selectedApplication, inputValue);
        }
    }, [inputValue])

    const debouncedFilter = useCallback(debounce((app, str) => { getFilteredDevices(app, str) }, 200), []);

    const filterOptions = (options, state) => {
        if (state.inputValue !== "") {
            if (isLargeNumbers) {
                const filtered = filteredOptions ? filter(filteredOptions, state) : filter([], state);
                return filtered;
            }
            else return filter(options, state);
        }
        else return options;
    }

    const MenuProps = { PaperProps: { style: { maxHeight: 375 } } };

    return (
        <>
            <CustomDrawer isOpen={isOpen} closeFunc={closeDrawer}>
                <h3>Device Settings</h3>
                <Box className="drawer-container">
                    <FormControl fullWidth>
                        <InputLabel id="select-sensor-label">Select sensor*</InputLabel>
                        <CustomSelect
                            label="Select sensor*"
                            labelId="select-sensor-label"
                            value={selectedSensor.sensor}
                            displayEmpty
                            onChange={e => handleSensorChange(e.target.value)}
                            required
                            MenuProps={MenuProps}
                        >
                            {sensors.map((option, index) => <MenuItem value={option.sensor} key={index}>{option.sensor}</MenuItem>)}
                        </CustomSelect>
                    </FormControl>
                    {isCustomer
                        ? <FormControl fullWidth>
                            {subCustomers
                                ? <>
                                    <InputLabel id="select-sub-customer-label">Select sub-customer</InputLabel>
                                    <CustomSelect
                                        label="Select sub-customer (optional)"
                                        labelId="select-sub-customer-label"
                                        value={selectedSubCustomerId}
                                        displayEmpty
                                        onChange={e => setSelectedSubCustomerId(e.target.value)}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="*"><i>All sub-customers (including none)</i></MenuItem>
                                        {sortArrayOfObjects(subCustomers, "name").map((option, index) => <MenuItem value={option.id.id} key={index}>{option.name}</MenuItem>)}
                                    </CustomSelect>
                                </>
                                : <CustomField placeholder="There is no sub-customers" disabled fullWidth />
                            }
                        </FormControl>
                        : null}
                    <FormControl fullWidth>
                        {applications && applications.length > 0
                            ? <>
                                <InputLabel id="select-application-label">Select application*</InputLabel>
                                <CustomSelect
                                    label="Select application*"
                                    labelId="select-application-label"
                            value={selectedApplication}
                            displayEmpty
                            onChange={e => setSelectedApplication(e.target.value)}
                            required
                            MenuProps={MenuProps}
                        >
                            {(applications.length > 1)
                                ? <MenuItem value="*"><i>All applications</i></MenuItem>
                                : null}
                            {sortArrayOfObjects(applications, "name").map((option, index) => <MenuItem value={option.id} key={index}>{option.name}</MenuItem>)}
                        </CustomSelect>
                            </>
                            : <CustomField placeholder="There is no available applications" disabled fullWidth />
                        }
                    </FormControl>
                    <FormControl fullWidth>
                        {devices ?
                            <>
                                {(devices.length > 0)
                                    ? <CustomAutocomplete
                                        inputValue={inputValue}
                                        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                                        value={selectedDevice.id !== "" ? selectedDevice : null}
                                        onChange={(_, device) => setSelectedDevice(device)}
                                        filterOptions={filterOptions}
                                        getOptionDisabled={(option) => option.id === "tooManyDevs"}
                                        disabled={!selectedApplication}
                                        options={devices}
                                        getOptionLabel={(device) => device.name ? device.name : ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        disableClearable
                                        renderInput={(params) => (
                                            (selectedDevice && selectedDevice.id !== "")
                                                ? <CustomField
                                                    {...params}
                                                    label="Select device*"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                <InputAdornment position="end">
                                                                    {selectedDevice.modelName && <Chip className="device-model" size="small" label={selectedDevice.modelName} />}
                                                                </InputAdornment>
                                                                {params.InputProps?.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                                : <CustomField {...params} label="Select device*" />
                                        )}
                                    />
                                    : <CustomField placeholder="There is no available devices" disabled fullWidth />}
                            </>
                            : <CustomField placeholder="Select device*" disabled fullWidth />
                        }
                    </FormControl>
                </Box>
                <Divider />
                <h3>Application</h3>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Uplink"} onClick={() => setLinkType("Uplink")}>
                            <ListItemText primary="Packet Decoder" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Downlink"} onClick={() => setLinkType("Downlink")}>
                            <ListItemText primary="Packet Encoder" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Statistics"} onClick={() => setLinkType("Statistics")}>
                            <ListItemText primary="Statistics" />
                            <Chip label="Beta" size="small" color="warning"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </CustomDrawer>
            <Box className="settings-btn d-flex-center" onClick={openDrawer}>
                <Typography variant="title3">{selectedSensor.sensor}</Typography>
            </Box>
            <Main open={isOpen}>
                {selectedDevice.id && showContent()}
            </Main>
        </>
    );
}
