import React from "react";
import { useState } from "react";
import { getSensorModels } from "../Worker/filemanager.js";
import ManualUplink from "./ManualUplink";
import ManualDownlink from "./ManualDownlink";
import ManualStatistics from "./ManualStatistics.jsx";
import { Divider, Typography, FormControl, MenuItem, Box, List, ListItem, ListItemButton, ListItemText, Chip } from '@mui/material';
import { CustomSelect, Main } from "../common/StyledComponents"
import CustomDrawer from "../common/CustomDrawer/index.jsx";
import { sortArrayOfObjects } from "../../utils.js"

const getSensors = () => {
    let sensors = getSensorModels() || [];
    sensors = sortArrayOfObjects(sensors, "sensor");
    sensors = [{ sensor: "Custom sensor" }, ...sensors]
    return sensors;
}

export default function ManualDecoder() {
    const sensors = getSensors();
    const [selectedSensor, setSelectedSensor] = useState(sensors[0]);
    const [linkType, setLinkType] = useState("Uplink");

    const [isOpen, setIsOpen] = useState(true);
    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);

    const handleSensorChange = (sensorName) => {
        let sensor = sensors.find(sensor => sensor.sensor === sensorName);
        if (!sensor.encoder) setLinkType("Uplink");
        setSelectedSensor(sensor);
    }

    const showContent = () => {
        switch (linkType) {
            case "Uplink":
                return <ManualUplink sensorDecoder={selectedSensor.decoder} />
            case "Downlink":
                return <ManualDownlink sensorEncoder={selectedSensor.encoder}
                    sensorModelName={selectedSensor.model} />
            case "Statistics":
                return <ManualStatistics/>
        }
    }

    return (
        <>
            <CustomDrawer isOpen={isOpen} closeFunc={closeDrawer}>
                <h3>Device Settings</h3>
                <Box className="drawer-container">
                    <FormControl fullWidth>
                        <CustomSelect
                            value={selectedSensor.sensor}
                            displayEmpty
                            onChange={e => { handleSensorChange(e.target.value) }}
                            required
                            MenuProps={{ PaperProps: { style: { maxHeight: 375 } } }}
                        >
                            <MenuItem value="" style={{ display: "none" }}>Loading sensors...</MenuItem>
                            {sensors.map((option, index) => <MenuItem value={option.sensor} key={index}>{option.sensor}</MenuItem>)}
                        </CustomSelect>
                    </FormControl>
                </Box>
                <Divider />
                <div>
                    <h3>Application</h3>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton selected={linkType === "Uplink"} onClick={() => setLinkType("Uplink")}>
                                <ListItemText primary="Packet Decoder" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding >
                            <ListItemButton selected={linkType === "Downlink"} disabled={!selectedSensor.encoder} onClick={() => setLinkType("Downlink")}>
                                <ListItemText primary="Packet Encoder" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton selected={linkType === "Statistics"} onClick={() => setLinkType("Statistics")}>
                                <ListItemText primary="Statistics" />
                                <Chip label="Beta" size="small" color="warning"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </CustomDrawer>
            <Box className="settings-btn d-flex-center" onClick={openDrawer}>
                <Typography variant="title3">{selectedSensor.sensor}</Typography>
            </Box>
            <Main open={isOpen}>
                {showContent()}
            </Main>
        </>
    );
}
