import React, { useState, useEffect, memo, useContext } from "react";
import { CardContent, Box, CircularProgress, Typography } from '@mui/material';
import { CustomCard } from "../common/StyledComponents";
import NotificationContext from "../../context/NotificationContext";
import { isDownlink, isUplink, STATISTIC_MIN_LIMIT, MAX_LOADED_PACKETS, MTYPES } from "../../constants/types.constant";
import { getMissedUplinks, getMissedDownlinks, getUsedDRMap, getFrequencies, getRegionByPacket, getAverageValue, removeUplinkDuplicates } from "../Worker/statistics";
import StatisticPanel from "../Statistics/StatisticPanel";
import { getV2DevicePackets } from "../Worker/ns.js";

const MAX_MONTHS = 6;

const Statistics = memo(({ deviceId }) => {

    const notificationCtx = useContext(NotificationContext);

    const [lastJoinAccept, setLastJoinAccept] = useState(null);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);

    const [packets, setPackets] = useState(null);
    const [lastPacket, setLastPacket] = useState(null);
    const [uplinkList, setUplinkList] = useState([]);
    const [uniqueUplinkList, setUniqueUplinkList] = useState([]);
    const [uplinkFrequencies, setUplinkFrequencies] = useState([]);
    const [missedUplinks, setMissedUplinks] = useState({});
    const [downlinkList, setDownlinkList] = useState([]);
    const [downlinkFrequencies, setDownlinkFrequencies] = useState([]);
    const [missedDownlinks, setMissedDownlinks] = useState({});
    const [region, setRegion] = useState(null);
    const [usedDRMap, setUsedDRMap] = useState({});

    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (packets) {
            setUplinkList(packets.filter(isUplink));
            setDownlinkList(packets.filter(isDownlink));
            setRegion(getRegionByPacket(packets[0]));
            setMissedDownlinks(getMissedDownlinks(packets));
        }
    }, [packets])

    useEffect(() => {
        if (uplinkList) {
            setUsedDRMap(getUsedDRMap(region, uplinkList));
            setUplinkFrequencies(getFrequencies(uplinkList));
            setUniqueUplinkList(removeUplinkDuplicates(uplinkList));
        }
    }, [uplinkList])

    useEffect(() => {
        if (uniqueUplinkList) {
            setMissedUplinks(getMissedUplinks(uniqueUplinkList));
        }
    }, [uniqueUplinkList])

    function getPackets() {
        const today = new Date();
        let endMillis = Date.now();
        const startFrom = new Date(today);
        startFrom.setMonth(today.getMonth() - MAX_MONTHS);
        getV2DevicePackets(deviceId, 1, startFrom.getTime(), endMillis, MTYPES.JOIN_ACCEPT)
            .then((resp) => {
                if (resp && resp.length === 1) {
                    let lastJoin = resp[0];
                    setLastJoinAccept(lastJoin);
                    setLastPacket(lastJoin);
                    getV2DevicePackets(deviceId, 1000, lastJoin.ts, Date.now())
                        .then((packets) => {
                            setPackets(packets);
                            setProgress(false);
                            // TODO: load more packets to get statistics for the most recent N packets
                        })
                        .catch((error) => {
                            setProgress(false);
                        })
                } else {
                    setLastJoinAccept(null);
                    getV2DevicePackets(deviceId, 1000, startFrom.getTime(), Date.now())
                        .then((packets) => {
                            setPackets(packets);
                            setProgress(false);
                        })
                        .catch((error) => {
                            setProgress(false);
                        })
                }
            })
            .catch((e) => {
                notificationCtx.error("Something went wrong!");
                setProgress(false);
            })
    }

    useEffect(() => {
        setProgress(true);
        setPackets(null);
        getPackets();
    }, [deviceId])

    useEffect(() => {
        if (downlinkList) {
            setDownlinkFrequencies(getFrequencies(downlinkList))
        }
    }, [downlinkList])

    const getAverageRSSI = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "rssi");
    const getAverageSNR = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "loRaSNR");

    return (
        <>
            {packets // TODO: progress
                ? <Box>
                    <CustomCard sx={{ overflow: "auto" }}>
                        <CardContent sx={{ px: 4, py: 2 }}>
                            {packets && packets.length > 0
                                ? <>
                                    {!lastJoinAccept &&
                                        <Box>
                                            {/* TODO: there is no limited for statistics anymore. it's just latest 1k packets in the last 6 month */}
                                            {isLimitExceeded
                                                ? `The limit (${MAX_LOADED_PACKETS}) of loaded packets has been exceeded without finding the Join frame. Below are statistics for the last ${MAX_LOADED_PACKETS} packets.`
                                                : `Could not find any Join frame! Below is statistic for all packets.`
                                            }
                                        </Box>}
                                    <StatisticPanel
                                        time={new Date(lastPacket.ts).toLocaleString()}
                                        packetsCounter={{
                                            downlinks: downlinkList.length,
                                            uplinks: uplinkList.length,
                                            uniqueUplinks: uniqueUplinkList.length,
                                            total: packets.length,
                                            uplinkFrequencies: uplinkFrequencies.length,
                                            downlinkFrequencies: downlinkFrequencies.length
                                        }}
                                        averageData={{
                                            RSSI: getAverageRSSI(),
                                            limitRSSI: getAverageRSSI(STATISTIC_MIN_LIMIT),
                                            SNR: getAverageSNR(),
                                            limitSNR: getAverageSNR(STATISTIC_MIN_LIMIT)
                                        }}
                                        usedDRMap={usedDRMap}
                                        missedUplinks={missedUplinks}
                                        missedDownlinks={missedDownlinks}
                                        isJoin={lastJoinAccept ? true : false}
                                        region={region}
                                        showRecentLimit={uplinkList.length > STATISTIC_MIN_LIMIT} />
                                </>
                                : <Box sx={{ px: 4, py: 2 }}>
                                    {!lastJoinAccept
                                        ? `There are no real-time packets for last ${MAX_MONTHS} months to get statistics!`
                                        : "There are no packets after joining!"
                                    }
                                </Box>
                            }
                        </CardContent>
                    </CustomCard>
                </Box>
                : <Box className="d-flex-column-center" height="100%">
                    <CircularProgress color="primary" />
                    <Typography variant="title2" sx={{ mt: 2 }}>Trying to find last Join frame...</Typography>
                </Box>}
        </>)
});

export default Statistics;