import { useEffect, useMemo, useCallback, useState } from 'react';
import { sortPackets } from './components/Worker/statistics';

const useWebSocket = () => {
    const [realTimePackets, setRealTimePackets] = useState([]);
    const token = localStorage.getItem("token");
    const nsurl = localStorage.getItem("host");
    
    const socket = useMemo(() => {
        return new WebSocket(`${nsurl.replace("http", "ws")}/api/ws?token=${token}`);
    }, [token, nsurl]);

    const getCmid = () => {
        let id = localStorage.getItem("id");
        id = parseInt(id) + 1;
        localStorage.setItem("id", id);
        return id;
    };

    const waitForSocket = (callback) => {
        setTimeout(() => {
            if (socket.readyState === 1) {
                console.log("Socket connected");
                callback();
            } else {
                waitForSocket(callback);
            }
        }, 1000);
    };

    const subscribeForDeviceStatsData = useCallback((deviceId) => {
        const id = getCmid();
        waitForSocket(() => {
            socket.send(JSON.stringify({
                "subCmds": [
                    {
                        "entityType": "DEVICE",
                        "entityId": deviceId,
                        "type": "STATS",
                        "cmdId": id
                    }
                ]
            }));
        });
        console.log("Subcribed to device : " + deviceId)
    }, [socket]);

    const unsubscribeFromDeviceStatsData = useCallback(() => {
        const id = localStorage.getItem("id");
        waitForSocket(() => {
            socket.send(JSON.stringify({
                "subCmds": [
                    {
                        "entityType": "DEVICE",
                        "type": "STATS",
                        "cmdId": id,
                        "unsubscribe": true
                    }
                ]
            }));
        });
        console.log("Unsubcribed from device!")
    }, [socket]);

    useEffect(() => {
        socket.onmessage = (event) => {
            try {
                const Data = JSON.parse(event.data);
                const id = parseInt(localStorage.getItem("id"));
                if (Data.subscriptionId === id) {
                    const newData = sortPackets(Data.data);
                    setRealTimePackets(newData);
                } else {
                    socket.send(JSON.stringify({
                        "subCmds": [
                            {
                                "entityType": "DEVICE",
                                "type": "STATS",
                                "cmdId": Data.subscriptionId,
                                "unsubscribe": true
                            }
                        ]
                    }));
                }
            } catch (error) {
                console.error(error);
            }
        };

        return () => {
            socket.close();
        };
    }, [socket]);

    return {
        subscribeForDeviceStatsData,
        unsubscribeFromDeviceStatsData,
        realTimePackets,
    };
};

export default useWebSocket;