import React, { useState, useEffect, useContext } from "react";
import lora from "lora-packet";
import useWebSocket from '../../useWebSocket';
import { TableContainer, TableBody, TableHead, TableRow, TableCell, FormControl, CardMedia, Tooltip, TableFooter, Button } from '@mui/material';
import { CardHeader, CardContent, Box, Tabs, Tab, CircularProgress, MenuItem, IconButton } from '@mui/material';
import { CustomCard, CustomSelect, CustomTable } from "../common/StyledComponents";
import NotificationContext from "../../context/NotificationContext";
import CodeBox from "../common/CodeBox";
import RefreshIcon from "../../images/refresh.png";
import { handleLoraMessage, formatLoraMessage } from "../../loraDecrypt";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { sortPackets } from "../Worker/statistics.js";
import { getV2DevicePackets } from "../Worker/ns.js";
import { isJoinAccept, isJoin, isJoinRequest, PACKETS_PER_PAGE } from "../../constants/types.constant.js";

const DAYS_AGO = 1;

function getDateFromNow(days = 0, months = 0) {
    const today = new Date();
    const resultDate = new Date(today);
    if (days) {
        resultDate.setDate(today.getDate() - days);
    }
    if (months) {
        resultDate.setMonth(today.getMonth() - months);
    }
    return resultDate;
}

const removeDuplicatePackets = (packets) => {
    const isDuplicate = (p1, p2) => p1 && p2 && p1.fcntUp === p2.fcntUp && p1.messageType === p2.messageType;
    if (packets) {
        let newPackets = [];
        for (let i = 0; i < packets.length; i++) {
            if (!isDuplicate(packets[i], packets[i + 1])) {
                newPackets.push(packets[i]);
            }
        }
        return newPackets;
    }
    return null;
}

const Uplink = ({ sensorDecoder, device, updateKeys }) => {

    const notificationCtx = useContext(NotificationContext)
    const [allPackets, setAllPackets] = useState(null);
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [unique, setUnique] = useState(true);
    const [uniquePackets, setUniquePackets] = useState(null);
    const [messages, setMessages] = useState(null);

    const [selectedPacket, setSelectedPacket] = useState("");
    const [activeUpId, setActiveUpId] = useState(1);
    
    const [appMessage, setAppMessage] = useState(null);
    const [networkMessage, setNetworkMessage] = useState(null);
    const [loraMessage, setLoraMessage] = useState(null);

    const [timepickerDate, setTimepickerDate] = useState(null);
    const [progress, setProgress] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const { subscribeForDeviceStatsData, unsubscribeFromDeviceStatsData, realTimePackets  } = useWebSocket();

    useEffect(()=>{
        let startTime = getDateFromNow(DAYS_AGO)
        setTimepickerDate(startTime);
    }, [device.id])

    useEffect(() => {
        subscribeForDeviceStatsData(device.id);

        return () => {
            unsubscribeFromDeviceStatsData();
        };
    }, [timepickerDate]);

    useEffect(() => {
        let packetsWithInfo = addSessionKeysInformation(realTimePackets);
        setAllPackets(packets => [...packetsWithInfo, ...packets || []]);
        if (realTimePackets.length === 1 && isJoin(realTimePackets[0])) {
            updateKeys();
        }
    }, [realTimePackets]);

    useEffect(() => {
        if (allPackets) {
            let uniquePacketsWithSessionKeys = removeDuplicatePackets(allPackets);
            setUniquePackets(uniquePacketsWithSessionKeys);
            setMessages(unique ? uniquePacketsWithSessionKeys : allPackets);
        } else {
            setUniquePackets(null);
            setMessages(null);
        }
    }, [allPackets])

    useEffect(() => {
        setMessages(unique ? uniquePackets : allPackets);
    }, [unique])

    useEffect(() => {
        if (selectedPacket !== "") {
            decodePacket()
            setNetworkMessage(JSON.stringify(selectedPacket, hidePrivateFields, 4))
        }
        else {
            setNetworkMessage(null)
            setLoraMessage(null);
            setAppMessage(null);
        }
    }, [selectedPacket, sensorDecoder, messages])

    function updatePackets(startMillis, endMillis) {
        getV2DevicePackets(device.id, PACKETS_PER_PAGE, startMillis, endMillis)
            .then((resp) => {
                setIsAllLoaded(resp.length < PACKETS_PER_PAGE);
                let newPackets = sortPackets(resp);
                let packetsWithInfo = addSessionKeysInformation(newPackets)
                setAllPackets(packetsWithInfo);
                setProgress(false);
            })
            .catch((e) => {
                notificationCtx.error("Something went wrong! Try again");
                setProgress(false);
            })
    }

    useEffect(() => {
        reset();
        if (device.id && timepickerDate) {
            setProgress(true);
            let startMillis = timepickerDate.getTime();
            let endMillis = Date.now();
            updatePackets(startMillis, endMillis);
        }
    }, [timepickerDate])

    const reset = () => {
        setAllPackets(null);
        setUnique(true);
        setSelectedPacket("");
    }

    const loadMore = () => {
        let lastElement = allPackets?.slice(-1).pop();
        if (lastElement) {
            setLoadingMore(true);
            let startMillis = timepickerDate.getTime();
            let endMillis = lastElement.ts;
            getV2DevicePackets(device.id, PACKETS_PER_PAGE, startMillis, endMillis)
                .then((resp) => {
                    setIsAllLoaded(resp.length < PACKETS_PER_PAGE);
                    let newPackets = sortPackets(resp);
                    let packets = [...allPackets || [], ...newPackets];
                    let packetsWithInfo = addSessionKeysInformation(packets)
                    setAllPackets(packetsWithInfo);
                    setLoadingMore(false);
                })
                .catch((e) => {
                    notificationCtx.error("Could not load more packets! Please try again");
                    setLoadingMore(false);
                })
        }
    }

    const hidePrivateFields = (key, value) => {
        if (key == "sessionKeys") return undefined;
        else return value;
    }

    const uplinks = [
        { id: 1, text: "App" },
        { id: 2, text: "Network Server" },
        { id: 3, text: "LoRa MAC" },
    ];

    const handleTabChange = (event, newValue) => setActiveUpId(newValue);

    const shortcutsItems = [
        {
            label: 'A day ago',
            getValue: () => getDateFromNow(1)
        },
        {
            label: 'A week ago',
            getValue: () => getDateFromNow(7)
        },
        {
            label: 'A month ago',
            getValue: () => getDateFromNow(0, 1)
        },
        {
            label: '3 months ago',
            getValue: () => getDateFromNow(0, 3)
        }
    ]

    async function decodePacket() {
        try {
            let input = selectedPacket.rawPayload;
            let fcntMSB32 = new Buffer(2);
            let selectedPacketFCnt = selectedPacket.messageType === "Uplink" ? selectedPacket.fcntUp : selectedPacket.fcntDown;
            if (selectedPacketFCnt > 65536) {
                let msb = (selectedPacketFCnt >> 16) & 0xFF;
                fcntMSB32.writeUInt16LE(msb, 0);
            }
            let payloadData = lora.fromWire(Buffer.from(input, 'base64'))
            let appKey = Buffer.from(device.appKey, "hex");
            if (payloadData.getMType() === "Join Accept") {
                payloadData = lora.fromWire(lora.decryptJoinAccept(payloadData, appKey));
            }
            let decodeFileName = sensorDecoder ? sensorDecoder : "/_default-decoder"
            let decodeFile = require("../../sensordecoders" + decodeFileName)
            let networkSKey = selectedPacket.sessionKeys?.NwkSKey;
            let applicationSKey = selectedPacket.sessionKeys?.AppSKey;
            let decoded = handleLoraMessage(payloadData, networkSKey, appKey, fcntMSB32);
            setLoraMessage(formatLoraMessage(decoded));
            if (applicationSKey || networkSKey) {
                if (payloadData.FRMPayload) {
                    if (Number.isFinite(payloadData.FPort[0])) {
                        let decrypted = lora.decrypt(payloadData, applicationSKey, networkSKey, fcntMSB32)
                        let inputDC = {
                            bytes: decrypted,
                            fPort: payloadData.FPort[0]
                        }
                        setAppMessage(JSON.stringify(decodeFile.decodeUplink(inputDC), undefined, 4))
                    } else setAppMessage("Not a valid packet.")
                } else setAppMessage("This packet doesn't have FRMPayload to decode.")
            } else setAppMessage("Could not find last Join frames to generate session keys to decrypt the packet. Please load more packets.")
        } catch (e) {
            console.log(e);
            setAppMessage(null)
            notificationCtx.error(e.message);
        }
    }

    const forwardTraverse = (packets) => {
        let packetsWithSessionKeys = packets;
        if (packets) {
            let currentSessionKeys = device.nwkSKey && device.appSKey ? {
                NwkSKey: Buffer.from(device.nwkSKey, "hex"),
                AppSKey: Buffer.from(device.appSKey, "hex")
            } : null;
            for (let i = 0; i < packetsWithSessionKeys.length; i++) {
                if (isJoinAccept(packetsWithSessionKeys[i])) break;
                if (!packetsWithSessionKeys[i].sessionKeys) {
                    packetsWithSessionKeys[i].sessionKeys = currentSessionKeys;
                }
            }
        }
        return packetsWithSessionKeys;
    }

    const backwardTraverse = (packets) => {
        let packetsWithSessionKeys = packets;
        let currentNetId, currentAppNonce, currentDevNonce;
        let currentSessionKeys = null;
        if (!device.appKey) {
            notificationCtx.error("There is no application key for the selected device!");
            return packetsWithSessionKeys;
        }
        let appKey = Buffer.from(device.appKey, "hex");
        if (packetsWithSessionKeys) {
            for (let i = packetsWithSessionKeys.length - 1; i >= 0; i--) {
                if (!packetsWithSessionKeys[i].sessionKeys) {
                    if (isJoinAccept(packetsWithSessionKeys[i])) {
                        let payloadData = lora.fromWire(Buffer.from(packetsWithSessionKeys[i].rawPayload, 'base64'))
                        payloadData = lora.fromWire(lora.decryptJoinAccept(payloadData, appKey));
                        currentAppNonce = payloadData.AppNonce;
                        currentNetId = payloadData.NetID;
                        if (currentNetId && currentAppNonce && currentDevNonce) {
                            currentSessionKeys = lora.generateSessionKeys(appKey, currentNetId, currentAppNonce, currentDevNonce);
                        }
                    }
                    else if (isJoinRequest(packetsWithSessionKeys[i])) {
                        let payloadData = lora.fromWire(Buffer.from(packetsWithSessionKeys[i].rawPayload, 'base64'))
                        currentDevNonce = payloadData.DevNonce;
                    }
                    packetsWithSessionKeys[i].sessionKeys = currentSessionKeys;
                } else break;
            }
        }
        return packetsWithSessionKeys;
    }

    const addSessionKeysInformation = (packets) => {
        if (packets && packets.length > 0) {
            let packetsWithSessionKeys = forwardTraverse(packets);
            packetsWithSessionKeys = backwardTraverse(packetsWithSessionKeys);
            return packetsWithSessionKeys;
        }
        return packets;
    }

    return (
        <>
                <Box className="d-flex">
                    <CustomCard sx={{ width: '52%' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CardHeader className="d-flex" sx={{ textAlign: "start !important" }}
                                title="Packet Decoder"
                                action={
                                    <DatePicker label="Start from" value={timepickerDate}
                                        disabled={progress}
                                        onChange={setTimepickerDate} disableFuture
                                        minDate={new Date(device.createdTime)} 
                                        sx={{ maxWidth: "170px" }}
                                        slotProps={{ shortcuts: { items: shortcutsItems } }}
                                    />
                                }
                            />
                        </LocalizationProvider>
                        <CardContent>
                            { progress
                                ? <Box className="d-flex-column-center" height="100%">
                                    <CircularProgress color="primary" />
                                </Box>
                                : messages &&
                                <TableContainer sx={{ height: "100%", overflowY: "auto" }}>
                                <CustomTable stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Time</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Port</TableCell>
                                            <TableCell>
                                                <FormControl variant="standard" size="small" >
                                                    <CustomSelect sx={{ fontSize: "1em", marginBottom: "-5px", "& .MuiSelect-standard": { paddingLeft: "5px" } }}
                                                        value="" displayEmpty
                                                        onChange={e => setUnique(Boolean(e.target.value))}>
                                                        <MenuItem value={""} style={{ display: "none" }}>Fcnt</MenuItem>
                                                        <MenuItem disabled={unique} value={1}>Show unique</MenuItem>
                                                        <MenuItem disabled={!unique} value={0}>Show all</MenuItem>
                                                    </CustomSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Spread Factor" placement="top"><span>SF</span></Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Duty Cycled" placement="top"><span>DC</span></Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(messages.length > 0)
                                            ? messages.map((val, index) =>
                                                <TableRow
                                                    key={index}
                                                    className={val === selectedPacket ? "selected" : ""}
                                                    onClick={() => setSelectedPacket(val)}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <TableCell>{new Date(val.ts).toLocaleString()}</TableCell>
                                                    <TableCell>{val.messageType}</TableCell>
                                                    <TableCell>{val.fport !== null ? val.fport : "-"}</TableCell>
                                                    <TableCell>{val.messageType === "Uplink" ? val.fcntUp : val.fcntDown}</TableCell>
                                                    <TableCell>{val.spreadFactor}</TableCell>
                                                    <TableCell>{val.dutyCycled ? "Yes" : ""}</TableCell>
                                                </TableRow>
                                            )
                                            : <TableRow>
                                                <TableCell colSpan={6}>No packets found for this period. Please select a new timeframe.</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    {!isAllLoaded && <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                                <Button disabled={loadingMore} onClick={loadMore}>{loadingMore ? 'Loading...' : 'Load more packets'}</Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>}
                                </CustomTable>
                            </TableContainer> }
                        </CardContent>
                    </CustomCard>
                    <CustomCard sx={{ width: '46%' }}>
                        <CardMedia>
                            <Box className="d-flex" sx={{ alignItems: "center" }}>
                                <Tabs value={activeUpId} onChange={handleTabChange}>
                                    {uplinks.map(el => <Tab label={el.text} key={el.id} value={el.id} />)}
                                </Tabs>
                                <IconButton onClick={updateKeys} color="inherit">
                                    <Tooltip title="Refresh keys">
                                        <Box component="img" src={RefreshIcon} />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </CardMedia>
                        <CardContent>
                            <CodeBox>
                                {activeUpId === 1
                                    ? appMessage || "No payload to decode."
                                    : activeUpId === 2
                                        ? networkMessage || "No payload to decode."
                                        : activeUpId === 3
                                            ? loraMessage || "No payload to decode."
                                            : <p>No payload to decode.</p>
                                }
                            </CodeBox>
                        </CardContent>
                    </CustomCard>
                </Box>
        </>)
};

export default Uplink;
